import React, {useState} from "react";

 
function Booking() {
    //form submit
    const [formData, setFormData] = useState(
        {
            name:"",
            number:"",
            date:"",
            session:""
            
        }
    )


   function handleChange(e) {
        setFormData(prevFormData =>{
          return{ ...prevFormData, 
             [e.target.name]: e.target.value
        }})
    }

    function handleSubmit(e){
        console.log(e)
        e.preventDefault()
    }
 
    return(
        <div id="Booking" className="
        flex 
        text-white
         flex-col flex-wrap mx-auto justify-evenly p-2">
              {/*pricing */}
            <div className="mx-auto w-full bg-white bg-opacity-40 overflow-x-hidden  overflow-scroll flex flex-col text-center "> 
            <h1 className="text-gray-900 text-2xl font-bold">Pricing</h1>
            <p className="text-lg font-semibold"> Minimum is $100. I charge $100 an hour.</p>
            <p>The pricing of your tattoo depends on the size, placement, and overall design of the piece. A tattoo with more details (lines, </p>
            <p>
                Estimates
            </p>
            <ul >
                <li>
                    1 hour session:1-2 small tattoos 
                </li>
                <li>
                    2 hour session: simple medium sized tattoo
                </li>
                <li>
                    3 hour session: intricate meduim or simple large tattoo
                </li>
            </ul>
            
            </div>
       
            {/*booking */}
            <form
             className="grid 
               pb-2 
               my-1
                 mx-auto 
                 p-3 
                 bg-inherit" 
             action='/'
              method='post' 
              onSubmit={handleSubmit}>
              
              <input 
                type="text" 
                 placeholder="enter name"
                  className=" text-gray-900
                   text-center
                    font-semibold 
                    bg-gray-300
                      my-2 
                      rounded-2xl" 

                  name='name'
                  value={formData.name}
                  onChange={handleChange}/>
                
                <input  type='number'
                value={formData.number}
                placeholder="enter number"
                className="my-2 
                rounded-2xl
                 bg-gray-300 
                 text-gray-900
                  text-center
                   font-semibold" />

                <input type='date'
                 placeholder="date" 
                 className="text-gray-900 
                 text-center font-semibold
                  bg-gray-300 my-2 rounded-2xl 
                  px-2" name="date"
                  value={formData.date}/>
                
                
                <select 
                className="my-2 
                rounded-2xl
                 bg-gray-300 
                 text-gray-900
                  text-center
                   font-semibold" 
                   name='session' 
                   value={formData.session}>
                    <option value="" className="">choose session</option>
                    <option value="1 hour session">1 hour session</option>
                    <option value="2 hour session">2 hour session</option>
                    <option value="3 hour session">3 hour session</option>
                </select>
                <input type='submit' 
                        className="bg-gray-300
                        text-black
                        hover:bg-black
                        hover:text-sky-400
                        py-2
                        px-4
                        mx-auto 
                        rounded-2xl 
                        mt-2
                        mb-0
                        
                        text-center
                        font-bold
                        shadow shadow-white" />
            </form>

    </div>
    )
    
}

export default Booking