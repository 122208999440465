import React from "react"
import {Link} from 'react-router-dom'

function Footer(){

    return(
        <footer className='flex flex-col flex-wrap justify-evenly  bg-sky-500 mb-0 p-2 '>
             

            <ul className="flex justify-around">
            <Link to='./Pages/HOMEPAGE' className="text-xs">
                home
            </Link> 
            <Link to='./Pages/POLICIES' className="text-xs">
                Policy
            </Link> 
            <Link to='./Pages/BOOKING' className="text-xs">
                Booking
            </Link> 
            </ul>
            
             
            
            <p className="text-xs mt-0.5 pt-1">copyrighted 11/12/2021, developed by Kentravious Colson</p>
        </footer>
    )
}

export default Footer

