 
import React from "react";
import './index.css'
import {BrowserRouter, Route, Routes} from 'react-router-dom'
// componensts
import Header from "./Components/Header";
import Footer from "./Components/Footer";

//pages
 
import Homepage from "./Pages/HOMEPAGE";
import Portfolio from "./Pages/Portfolio";
import Booking from "./Pages/BOOKING";
import LandingPage from "./Pages/LANDINGPAGE";


function App() {
  return (
    <BrowserRouter>
      <div className="
     
      flex
       flex-col 
        text-center
         w-full
          h-screen
           justify-evenly
            
            ">
          
         
        <Header/>
        <div className="h-full bg-black ">

        <Routes className=''>
            <Route exact path='/' element={<LandingPage/>}/>
            <Route path='/Pages/HOMEPAGE' element={<Homepage/>}/>
            <Route path='/Pages/Portfolio' element={<Portfolio/>}/>
            <Route path='/Pages/BOOKING' element={<Booking/>}/>
           
          </Routes>
        </div>
     
          
        

     <Footer/>
      </div>
    </BrowserRouter>
    
  );
}

export default App;
