import React from "react";
import { Link } from "react-router-dom";
import '../index.css'

function LandingPage(props){
    return(
    
        <div 
        id="LandingPG"
        className="
        flex
         flex-col
          justify-evenly
           m-0
            p-2
            
              w-60%
              h-full">

            <h1 
            className="
            text-2xl 
            text-white
             font-bold
             mt-3">
                 Chaotic and Iconic
            </h1>
            
            <div 
            className="
            flex
             flex-col 
             pt-2
              pb-4
              ">

            <Link 
            to='./../Pages/BOOKING'
            
            className="
             my-2
            outline-none
          border-black
              border-2
               bg-transparent
                hover:text-white
                 hover:bg-black
                  rounded-2xl
                   py-1
                    px-2
                     font-bold">
                         Book Now
                         </Link>
            
            <Link to='../Pages/HOMEPAGE'
             
             className="
             outline-none
              border-white
               border-2 
               bg-transparent
                bg-black
                 text-white
                  hover:text-black
                   hover:bg-white
                    rounded-2xl
                     py-1
                      px-2
                       font-bold
                       w-">Look Around</Link>
            </div>
        </div>
    
    )
 
}

export default LandingPage