import React from "react";
import {Link} from 'react-router-dom'


function Homepage() {

    return(
         
    <main id="homepageBg" className="
    flex
    flex-col
     justify-evenly
      m-0
       p-2
      
         w-60%
         h-full" >
           <div id='intro-container' 
           className="
           
           flex
           flex-col
           justify between">

          <div id="intro" className="flex flex-col justify-evenly">

        <h2 className="text-white text-2xl my-4">
            Claud Tha Creator
        </h2>

        <p className="text-white">
        ​Licensed tattoo artist located in the atlanta metro area creating through different mediums since day one. Providing a safe environment filled with great vibes         
        </p>

        <Link to='../Pages/Portfolio'
        className="
      
         
          text-black
           bg-white
            hover:bg-gray-800
             hover:text-white
              rounded-2xl
               py-1
                px-2
                 font-bold
                 my-2">
          Meet me
          </Link>

          </div>
        <img src="" alt=""/>
           </div>
        
          {/*Portfolio */}
          

          <section id="portfolio" className="">
            <div className="bg-sky-500  h-10">
              <h2 classname=' w-full text-2xl text-bold'>
                Portfolio</h2>
            </div>
            <div className="w-full bg-red-600 h-4 p-5">
               
            </div>
            <div className="w-full bg-red-500 h-4 p-5">
               
               </div>
               <div className="w-full bg-red-400 h-4 p-5">
               
               </div>
               <div className="w-full bg-red-300 h-4 p-5">
               
               </div>
          </section>  

          <section id="services">
            <div className="text-white">
              <h2 className="text-white">Services</h2>
              <div>
                <img src="" alt=''/>
                <h3>service title</h3>
                <p>service description</p>
              </div>
            </div>
          </section>
    
        
    </main> 
    )
   
    


}

export default Homepage