import React from "react";

import '../index.css'
import {Link} from 'react-router-dom'

function Header(props){
    

    const toggleMenu = () => {
          
    }

    return(
    <header className='flex justify-between mt-0 py-2 bg-sky-500 '>
        <Link to='./Pages/HOMEPAGE'>
             <h1 className="mx-2 align-left text-gray-900 text-lg">
               CTC
               <span className="">
                 !</span>
              </h1>      
        </Link> 
  

      <button onClick={toggleMenu} className="mx-2 ">
      

      <img
       src="https://img.icons8.com/cotton/64/undefined/menu.png"
       className="h-8 w-8" alt=""/>
      
    </button>

      </header>
      
)
 }  

export default Header