import React from "react";
import {Link} from 'react-router-dom'

 


function Portfolio(){

    
    return(

          <div id="Portfolio" className="h-full w-full flex flex-col justify-evenly ">
        <h1>portfolio</h1>

        <div>
          <img src="" alt=""/>
        </div>


         
        <Link to='/Pages/POLICIES' 
         className="bg-gray-300 
         text-gray-900
              hover:bg-black
               hover:text-sky-400 
               py-2 
               px-4 
               mx-auto
                rounded-full
                 font-semibold
                  mb-2 
                  w-1/3
                   m-auto">
            POLICY
        </Link>  
    
    </div>
)
}
    

export default Portfolio